import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledIconX = styled.svg`
  &.path {
    fill: ${props => props.color || "#838A9A"};
  }
`;

export const IconX = ({ color = "#838A9A", className }) => {
  return (
    <StyledIconX
      className={`icon-x ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.1761 4.24219H19.9362L13.9061 11.0196L21 20.2422H15.4456L11.0951 14.6488L6.11723 20.2422H3.35544L9.80517 12.993L3 4.24219H8.69545L12.6279 9.35481L17.1761 4.24219ZM16.2073 18.6176H17.7368L7.86441 5.78147H6.2232L16.2073 18.6176Z"
      />
    </StyledIconX>
  );
};

IconX.propTypes = {
  color: PropTypes.string
};
