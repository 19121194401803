import React from "react";
import "./style.css";

export const Policy = () => {
  return (
    <div className="privacy-policy">
      <div className="frame">
        <div className="div">
          <div className="text-wrapper">믹스미 개인정보 처리방침</div>
          <div className="text-wrapper-2">적용 일자: 2023-12-19</div>
        </div>
        <div className="frame-2">
          <p className="element">
            인그래디언트(이하 &#34;회사&#34;)은(는) 믹스미을(를) 제공함에 있어 정보주체의 자유와 권리 보호를 위해
            「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고
            있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고,
            이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
            수립공개합니다.
          </p>
          <div className="div">
            <p className="text-wrapper-3">◾️ 개인정보의 처리목적, 수집 항목, 보유 및 이용기간</p>
            <p className="p">① 회사가 처리하고 있는 개인정보의 항목과 목적 및 보유기간은 아래와 같습니다.</p>
            <div className="frame-3">
              <div className="frame-4">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">구분(업무명)</div>
                </div>
                <div className="frame-5">
                  <div className="text-wrapper-5">회원가입 및 관리</div>
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-5">고객 상담 및 문의</div>
                </div>
                <div className="frame-7">
                  <div className="text-wrapper-5">제품 및 서비스</div>
                </div>
                <div className="frame-8">
                  <div className="text-wrapper-5">요금 결제 및 환불</div>
                </div>
                <div className="frame-9">
                  <div className="text-wrapper-4">자동으로 수집되는 정보</div>
                </div>
              </div>
              <div className="frame-10">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">처리목적</div>
                </div>
                <div className="frame-9">
                  <p className="text-wrapper-6">
                    본인 식별·인증
                    <br />
                    회원자격 유지·관리
                    <br />
                    각종 고지·통지사항 전달
                    <br />
                    서비스 부정가입 및 이용 방지
                  </p>
                </div>
                <div className="frame-11">
                  <p className="text-wrapper-6">
                    제품 및 서비스 상담
                    <br />
                    고객 문의 접수 및 처리
                    <br />
                    고객 불만 사항 처리
                    <br />
                    문의 접수 및 처리 이력관리
                  </p>
                </div>
                <div className="frame-9">
                  <p className="text-wrapper-6">
                    이용자 식별
                    <br />
                    품질 개선 및 고도화
                    <br />
                    서비스 환경의 유지·관리 및 개선
                    <br />
                    계약의 체결·유지·이행·관리·개선
                    <br />
                    서비스 이용 내역 제공
                    <br />
                    제품 및 서비스 제공
                  </p>
                </div>
                <div className="frame-11">
                  <p className="text-wrapper-6">
                    구매 및 요금결제·정산
                    <br />
                    계약서·청구서 발송
                    <br />
                    현금영수증 발행
                    <br />
                    결제내역 취소 및 환불처리
                    <br />
                    부정거래 확인
                    <br />
                    제세공과금 대납
                    <br />
                    포인트 적립 및 사용
                  </p>
                </div>
                <div className="frame-9">
                  <p className="text-wrapper-4">웹/앱 방문에 관한 통계 및 분석</p>
                </div>
              </div>
              <div className="frame-10">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">수집항목</div>
                </div>
                <div className="frame-5">
                  <p className="text-wrapper-7">필수: 이름, 아이디, 이메일주소, 비밀번호</p>
                </div>
                <div className="frame-12">
                  <p className="text-wrapper-8">필수: 이름, 이메일주소, 아이디, 상담 내역, 문의 내용</p>
                </div>
                <div className="frame-7">
                  <p className="text-wrapper-7">필수: 이름, 이메일주소, 아이디, 비밀번호</p>
                </div>
                <div className="frame-8">
                  <p className="text-wrapper-9">
                    필수: 이름, 이메일주소, 휴대전화번호, 신용카드정보(카드사명, 카드 번호, 유효기간, CVC), 입금자명,
                    은행명, 예금주, 계좌번호, 결제일시, 결제수단, 물품명, 결제 금액
                  </p>
                </div>
                <div className="frame-9">
                  <div className="text-wrapper-4">필수: 쿠키, 서비스 이용기록</div>
                </div>
              </div>
              <div className="frame-10">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">보유 및 이용기간</div>
                </div>
                <div className="frame-5">
                  <div className="text-wrapper-5">회원 탈퇴일로부터 30일</div>
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-5">문의 접수일로부터 2년</div>
                </div>
                <div className="frame-7">
                  <div className="text-wrapper-5">회원 탈퇴일로부터 30일</div>
                </div>
                <div className="frame-8">
                  <p className="text-wrapper-5">법정 의무 보유기간 만료 시까지</p>
                </div>
                <div className="frame-9">
                  <div className="text-wrapper-4">정보 수집일로부터 1년</div>
                </div>
              </div>
              <div className="frame-10">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">처리 근거</div>
                </div>
                <div className="frame-5">
                  <div className="text-wrapper-5">정보주체의 동의</div>
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-5">정보주체의 동의</div>
                </div>
                <div className="frame-7">
                  <div className="text-wrapper-5">정보주체의 동의</div>
                </div>
                <div className="frame-8">
                  <div className="text-wrapper-5">정보주체의 동의</div>
                </div>
                <div className="frame-9">
                  <div className="text-wrapper-4">정보주체의 동의</div>
                </div>
              </div>
            </div>
            <p className="p">
              ② 처리하고 있는 개인정보는 상기의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는
              개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
              <br />③ 회사는 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은
              개인정보 보유∙이용기간 내에서 개인정보를 처리∙보유합니다. 다만, 다음의 사유에 해당하는 경우에는 해당 사유
              종료시까지 처리∙보유합니다.
              <br />
              1) 관계 법령 위반에 따른 수사∙조사 등이 진행 중인 경우에는 해당 수사∙조사 종료 시까지
              <br />
              2) 홈페이지 이용에 따른 채권∙채무관계 잔존 시에는 해당 채권∙채무관계 정산 시까지
              <br />
              3) 관련 법령에 따른 의무 보유기간에 해당 시에는 해당 기간 종료 시까지
              <br />
              통신비밀보호법 : 웹사이트 방문 기록 등의 통신사실확인자료(3개월)
              <br />
              전자상거래법 : 계약 또는 청약철회 등에 관한 기록(5년)
              <br />
              전자상거래법 : 대금결제 및 재화 등의 공급에 관한 기록(5년)
              <br />
              전자상거래법 : 소비자의 불만 또는 분쟁처리에 관한 기록(3년)
              <br />
              전자상거래법 : 표시·광고에 관한 기록(6개월)
              <br />
              정보통신망법 : 본인확인에 관한 기록(6개월)
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-3">◾️ 개인정보의 제3자 제공</div>
            <p className="p">
              <span className="span">
                ① 회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의,
                법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
                제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
                <br />② 회사는 다음과 같이 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실
                등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다.
              </span>
              <a href="https://plip.kr/doc/static/plip.pdf" rel="noopener noreferrer" target="_blank">
                <span className="span">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">
                이 경우 회사는 근거법령에 의거하여 필요한 최소한의 개인정보만을 제공하며, 목적과 다르게 제공하지
                않겠습니다.
              </span>
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 개인정보 처리 업무의 위탁</p>
            <p className="p">
              ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
            </p>
            <div className="frame-3">
              <div className="frame-13">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">위탁받는 자(수탁자)</div>
                </div>
                <div className="frame-14">
                  <div className="text-wrapper-10">Google LLC</div>
                </div>
              </div>
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">위탁 업무</div>
                </div>
                <div className="frame-9">
                  <p className="text-wrapper-10">Google Analytics를 통한 서비스 이용/방문 트래픽 통계 분석</p>
                </div>
              </div>
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">재위탁</div>
                </div>
                <div className="frame-16" />
              </div>
            </div>
            <p className="p">
              ② 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지,
              기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등
              문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
              <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록
              하겠습니다.
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 개인정보의 파기 및 절차</p>
            <p className="p">
              ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
              개인정보를 파기합니다.
              <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성 되었음에도 불구하고 다른
              법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
              보관장소를 달리하여 보존합니다.
              <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
              <br />
              파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아
              개인정보를 파기합니다.
              <br />
              파기방법 : 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에
              기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 정보주체와 법정대리인의 권리·의무 및 행사방법</p>
            <p className="p">
              ① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
              <br />② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편,
              모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
              <br />③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이
              경우 &#34;개인정보 처리 방법에 관한 고시(제2020-7호)&#34; 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
              <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여
              정보주체의 권리가 제한 될 수 있습니다.
              <br />⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그
              삭제를 요구할 수 없습니다.
              <br />⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한
              자가 본인이거나 정당한 대리인인지를 확인합니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-3">◾️ 개인정보의 안전성 확보조치</div>
            <p className="p">
              ① 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
              <br />
              1) 관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육
              <br />
              2) 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화,
              보안프로그램 설치 및 갱신
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</p>
            <p className="cookie-http">
              <span className="span">
                ① 회사는 아래의 목적으로 &#34;쿠키(cookie)&#34;를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는
                서버(http)가 이용자의 컴퓨터 브라우저 또는 모바일 어플리케이션에게 보내는 소량의 정보로, 이용자의 컴퓨터
                내부 하드디스크 또는 모바일 기기에 저장됩니다.
                <br />
                1) 쿠키의 사용 목적: 이용자의 환경설정 유지, 로그인 상태 유지, 이용자의 서비스 이용 통계 분석을 통한
                서비스 개선, 맞춤형 서비스 제공, 관심 분야 분석을 통한 타겟 마케팅, 서비스 편의기능 제공, 각종 이벤트
                참여 정도 파악
                <br />
                2) 쿠키 저장 거부 시 불이익: 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다, 리워드 지급에
                제한이 생길 수 있습니다, 이용자에게 최적화된 서비스 제공과 정보 제공에 어려움이 있을 수 있습니다, 맞춤형
                서비스 이용에 어려움이 있을 수 있습니다
                <br />
                3) 쿠키의 설치·운영 및 거부: 브라우저나 앱의 종류에 따라 아래의 방법으로 쿠키의 저장을 거부할 수
                있습니다.
                <br />
                Chrome을 사용하는 경우 쿠키 설정 방법{" "}
              </span>
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=ko"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-11">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">Microsoft Edge를 사용하는 경우 쿠키 설정 방법 </span>
              <a
                href="https://support.microsoft.com/ko-kr/microsoft-edge/microsoft-edge%EC%97%90%EC%84%9C-%EC%BF%A0%ED%82%A4-%EC%82%AD%EC%A0%9C-63947406-40ac-c3b8-57b9-2a946a29ae09"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-11">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">Safari를 사용하는 경우 쿠키 설정 방법 </span>
              <a
                href="https://support.apple.com/ko-kr/guide/safari/sfri11471/mac"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-11">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">Chrome App을 사용하는 경우 쿠키 설정 방법 </span>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=ko&amp;co=GENIE.Platform%3DAndroid"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-11">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">Safari App을 사용하는 경우 쿠키 설정 방법 </span>
              <a href="https://support.apple.com/ko-kr/HT201265" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-11">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">
                Naver App을 사용하는 경우 쿠키 설정 방법: 설정 &gt; 인터넷 사용 기록 &gt; 쿠키 삭제
                <br />② 회사는 이용자에게 더 나은 이용 경험을 제공하기 위하여, 홈페이지/앱 접속 시 자동으로 방문기록과
                접속 수단에 관한 정보를 수집하여 분석하는 &#34;웹 로그 분석 도구&#34;를 사용합니다. 경우에 따라 회사는
                웹 로그 분석 업무를 타사에 위탁하며, 그 과정에서 수집된 정보가 국외로 이전될 수 있습니다. 이에 관한
                내용은 &#34;개인정보 처리의 위탁&#34; 부분에서 확인하실 수 있습니다.
                <br />
                1) 웹 로그 분석 도구의 사용 목적: 이용자의 서비스 이용 통계 분석을 통한 서비스 개선, 맞춤형 서비스 및
                혜택 제공, 맞춤형 광고 제공
                <br />
                2) 웹 로그 분석 도구의 거부∙차단 방법
                <br />
                [Google Analytics]{" "}
              </span>
              <a
                href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=ko"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-11">
                  보기
                  <br />
                </span>
              </a>
              <span className="span">
                3) 거부 시 불이익: 서비스 이용에 불이익은 없습니다. 다만, 서비스 개선을 위한 통계 분석에 영향을 끼칠 수
                있습니다.
              </span>
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 행태정보의 수집·이용 및 거부 등에 관한 사항</p>
            <p className="p">
              ① 회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기
              위하여 행태정보를 수집·이용하고 있습니다.
              <br />② 회사는 다음과 같이 행태정보를 수집·이용하고 있습니다.
            </p>
            <div className="frame-3">
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">행태정보 수집 항목</div>
                </div>
                <div className="frame-14">
                  <p className="text-wrapper-10">이용자의 서비스 방문이력, 검색이력, 구매이력, 클릭내역, 광고식별자</p>
                </div>
              </div>
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">수집 방법</div>
                </div>
                <div className="frame-14">
                  <p className="text-wrapper-9">이용자가 서비스 방문 및 실행 시 자동 수집</p>
                </div>
              </div>
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">수집 목적</div>
                </div>
                <div className="frame-9">
                  <p className="text-wrapper-10">
                    이용자의 관심, 성향에 기반한 개인 맞춤형 상품추천 서비스(광고 포함)를 제공
                  </p>
                </div>
              </div>
              <div className="frame-17">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">보유 및 이용기간</div>
                </div>
                <div className="frame-14">
                  <div className="text-wrapper-5">수집일로부터 1년 후 파기</div>
                </div>
              </div>
            </div>
            <p className="p">
              ③ 회사는 온라인 맞춤형 광고 사업자가 행태정보를 수집∙처리하도록 허용하고 있지 않습니다.
              <br />④ 회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및
              친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는
              민감한 행태정보를 수집하지 않습니다.
              <br />⑤ 회사는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인
              서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형
              광고를 제공하지 않습니다.
              <br />⑥ 정보주체는 아래와 같이 설정 변경을 통해 맞춤형 광고를 차단·허용할 수 있습니다.
              <br />[ 스마트폰의 광고식별자 차단/허용 ]<br />
              Android: 설정 &gt; 개인정보 보호 &gt; 광고 &gt; 광고 ID 재설정 또는 광고 ID 삭제
              <br />
              OS: 설정 &gt; 개인정보 보호 및 보안 &gt; 추적 &gt; 앱이 추적을 요청하도록 허용 끔<br />※ 모바일 OS 버전에
              따라 메뉴 및 방법이 다소 상이할 수 있습니다.
              <br />⑦ 정보주체는 본 개인정보 처리방침에 안내된 개인정보 보호책임자 및 개인정보 담당부서를 통하여
              행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 개인정보 보호책임자 및 개인정보 열람청구</p>
            <p className="p">
              ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
              피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.
              <br />② 정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
              회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
            </p>
            <div className="frame-3">
              <div className="frame-15">
                <div className="frame-18">
                  <div className="text-wrapper-4">구분</div>
                </div>
                <div className="frame-14">
                  <div className="text-wrapper-9">개인정보 보호책임자</div>
                </div>
                <div className="frame-14">
                  <div className="text-wrapper-9">개인정보 보호책임자</div>
                </div>
              </div>
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">담당자</div>
                </div>
                <div className="frame-9">
                  <div className="text-wrapper-10">
                    직책/직위: CEO
                    <br />
                    성명: 이준호
                  </div>
                </div>
                <div className="frame-9">
                  <div className="text-wrapper-10">
                    직책/직위: CEO
                    <br />
                    성명: 이준호
                  </div>
                </div>
              </div>
              <div className="frame-15">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">연락처</div>
                </div>
                <div className="frame-14">
                  <div className="text-wrapper-9">june@ingradient.ai</div>
                </div>
                <div className="frame-14">
                  <div className="text-wrapper-9">joosung@ingradient.ai</div>
                </div>
              </div>
            </div>
            <p className="p">
              ③ 정보주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리,
              피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 회사는 정보주체의 문의에
              대해 지체없이 답변 및 처리해 드릴 것입니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-3">◾️ 권익침해 구제 방법</div>
            <p className="p">
              ① 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고
              있으며, 신고나 상담이 필요한 경우 담당부서로 연락해 주시기 바랍니다.
              <br />② 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
              개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고,
              상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
              <br />
              개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
              <br />
              개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
              <br />
              대검찰청 : (국번없이) 1301 (www.spo.go.kr)
              <br />
              경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
              <br />③ 「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의
              처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의
              침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
              <br />
              중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-3">◾️ 개인정보 처리방침의 변경에 관한 사항</p>
            <p className="p">① 본 방침은 2023년 12월 19일부터 시행됩니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
