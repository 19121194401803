import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 416px;
  height: 416px;
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 335px;
    height: 335px;
  }

  .example-image {
    height: 100%;
    width: 100%;
    position: relative;
  }

  & .original-image-cover {
    display: flex;
    justify-content: center;
    
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  & .original-image {
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    // 체크 무늬 배경 추가
    background-image: 
      linear-gradient(45deg, #ccc 25%, transparent 25%), 
      linear-gradient(-45deg, #ccc 25%, transparent 25%), 
      linear-gradient(45deg, transparent 75%, #ccc 75%), 
      linear-gradient(-45deg, transparent 75%, #ccc 75%);
      background-size: 20px 20px;
      background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

    // 기존 이미지 오버레이
    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .after-image {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .overlap-group {
    left: 0px;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .replaced-image {
    left: 0px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  }

  .slide-switch {
    background-color: var(--g-button);
    border: 0.87px solid var(--g-white);
    border-radius: 12px;
    height: 24px;
    left: calc(50% - 12px);
    overflow: hidden;
    position: absolute;
    top: calc(50% - 3.5px);
    width: 24px;
  }

  .vertical-line {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 1px;
    background-color: white;
  }
  
  & .polygon {
    height: 7px;
    left: 4px;
    position: absolute;
    top: 8px;
    width: 6px;
  }

  & .polygon-2 {
    height: 7px;
    left: 13px;
    position: absolute;
    top: 8px;
    width: 6px;
  }
`;

const DraggableImage = ({ imageSrc, overlayImageSrc }) => {
  const [isDragging, setIsDragging] = useState(false);
  const frameRef = useRef(null);
  const elementRef = useRef(null);
  const lineRef = useRef(null);
  const dragItemRef = useRef(null);
  const frameWidthRef = useRef(0);

  useEffect(() => {
    if (frameRef.current) {
      frameWidthRef.current = frameRef.current.offsetWidth;
    }
  }, []);

  const onMouseDown = (e) => {
    setIsDragging(true);
    document.body.style.userSelect = 'none';
    const dragItemRect = dragItemRef.current.getBoundingClientRect();
    dragItemRef.current.offsetX = e.clientX - dragItemRect.left;
  };

  const onMouseMove = (e) => {
    if (isDragging && frameRef.current && elementRef.current && dragItemRef.current) {
      const minX = 0; // 이동 가능한 최소 X 좌표
      const maxX = frameRef.current.offsetWidth - dragItemRef.current.offsetWidth; // 이동 가능한 최대 X 좌표
      let newX = e.clientX - frameRef.current.getBoundingClientRect().left - dragItemRef.current.offsetX;
      newX = Math.max(minX, Math.min(maxX, newX)); // newX를 minX와 maxX 사이로 제한
  
      dragItemRef.current.style.left = `${newX}px`; // frame-11 위치 업데이트
      lineRef.current.style.left = `${newX + dragItemRef.current.offsetWidth / 2}px`; // vertical-line 위치 업데이트
  
      const clipStart = (newX + dragItemRef.current.offsetWidth / 2) / frameRef.current.offsetWidth * 100;
      elementRef.current.style.clipPath = `polygon(${clipStart}% 0, 100% 0, 100% 100%, ${clipStart}% 100%)`;
    }
  };
  
  const onMouseUp = () => {
    setIsDragging(false);
    document.body.style.userSelect = 'auto';
  };

  const onTouchStart = (e) => {
    if (e.touches.length === 1) { // Only proceed if single touch
      setIsDragging(true);
      document.body.style.userSelect = 'none';
      const touch = e.touches[0];
      const dragItemRect = dragItemRef.current.getBoundingClientRect();
      dragItemRef.current.offsetX = touch.clientX - dragItemRect.left;
    }
  };

  const onTouchMove = (e) => {
    if (e.touches.length === 1) { // Only proceed if single touch
      const touch = e.touches[0];
      onMouseMove({ clientX: touch.clientX });
    }
  };

  const onTouchEnd = () => {
    setIsDragging(false);
    document.body.style.userSelect = 'auto';
  };

  return (
    <Wrapper>
      <div className="example-image">
        <div className="original-image-cover">
          <img className="original-image" alt="Element" src={imageSrc} />
        </div>
        <div className="after-image" ref={frameRef}>
          <div className="overlap-group" 
               onMouseMove={onMouseMove} 
               onMouseUp={onMouseUp} 
               onTouchMove={onTouchMove} 
               onTouchEnd={onTouchEnd}>
            <img className="replaced-image" ref={elementRef} alt="Overlay Element" src={overlayImageSrc} />
            <div className="vertical-line" ref={lineRef}></div>
            <div className="slide-switch" ref={dragItemRef} 
                 onMouseDown={onMouseDown} 
                 onTouchStart={onTouchStart}>
              <img className="polygon" alt="Polygon" src="/img/polygon-1-6.svg" />
              <img className="polygon-2" alt="Polygon" src="/img/polygon-2-6.svg" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

DraggableImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  overlayImageSrc: PropTypes.string.isRequired
};

export default DraggableImage;
