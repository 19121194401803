import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button } from "../Button";
import styled from "styled-components";

const StyledFaq = styled.div`
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  max-width: 1440px;
  width: 100%;

  & .container-3 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    position: relative;
    width: 100%;
  }

  & .section-title-3 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 768px;
  }

  & .heading-3 {
    align-self: stretch;
    color: var(--g-title);
    font-family: var(--heading-h2-font-family);
    font-size: var(--heading-h2-font-size);
    font-style: var(--heading-h2-font-style);
    font-weight: var(--heading-h2-font-weight);
    letter-spacing: var(--heading-h2-letter-spacing);
    line-height: var(--heading-h2-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }

  & .text-3 {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: var(--text-large-normal-font-family);
    font-size: var(--text-large-normal-font-size);
    font-style: var(--text-large-normal-font-style);
    font-weight: var(--text-large-normal-font-weight);
    letter-spacing: var(--text-large-normal-letter-spacing);
    line-height: var(--text-large-normal-line-height);
    position: relative;
    text-align: center;
  }

  & .FAQ {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  & .divider {
    background-color: var(--g-line);
    height: 1px;
    position: relative;
    width: 100%;
  }

  & .title {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 20px 0px;
    position: relative;
    width: 100%;
  }

  & .title + p {
    padding-top: 12px;
    padding-bottom: 20px;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  & .question {
    color: var(--black);
    flex: 1;
    font-family: var(--text-medium-semi-bold-font-family);
    font-size: var(--text-medium-semi-bold-font-size);
    font-style: var(--text-medium-semi-bold-font-style);
    font-weight: var(--text-medium-semi-bold-font-weight);
    letter-spacing: var(--text-medium-semi-bold-letter-spacing);
    line-height: var(--text-medium-semi-bold-line-height);
    position: relative;
  }

  & .icon {
    height: 32px;
    position: relative;
    width: 32px;
  }

  & .more-question {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  & .content-8 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }

  & .heading-4 {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: var(--heading-h4-font-family);
    font-size: var(--heading-h4-font-size);
    font-style: var(--heading-h4-font-style);
    font-weight: var(--heading-h4-font-weight);
    letter-spacing: var(--heading-h4-letter-spacing);
    line-height: var(--heading-h4-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;

    @media (max-width: 768px) {
      font-size: var(--heading-h4-mobile-font-size);
    }
  }

  & .text-4 {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: var(--text-medium-normal-font-family);
    font-size: var(--text-medium-normal-font-size);
    font-style: var(--text-medium-normal-font-style);
    font-weight: var(--text-medium-normal-font-weight);
    letter-spacing: var(--text-medium-normal-letter-spacing);
    line-height: var(--text-medium-normal-line-height);
    position: relative;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  & .button-instance {
    border-color: var(--g-button) !important;
    border-radius: 44px !important;
    flex: 0 0 auto !important;
    margin-bottom: -1px !important;
    cursor: pointer !important;
  }

  & .design-component-instance-node {
    color: var(--g-button) !important;
  }
`;

export const Faq = ({
  className,
  text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
  questionClassName,
  text1 = "Question text goes here",
  iconClosed = "/img/icon-4.svg", // 닫혀있을 때의 아이콘
  iconOpened = "/img/icon-1.svg", // 열려있을 때의 아이콘
  questionClassNameOverride,
  img = "/img/icon-4.svg",
  divClassName,
  divClassNameOverride,
  questionClassName1,
  headingClassName,
  moreQuestion = "",
  moreD = "",
  q1 = "",
  q2 = "",
  q3 = "",
  q4 = "",
  q5 = "",
}) => {

  const [openQuestions, setOpenQuestions] = useState({});

  // 특정 질문의 펼침 상태를 토글하는 함수
  const toggleQuestion = index => {
    setOpenQuestions(prev => ({ ...prev, [index]: !prev[index] }));
  };
  
  const sendEmail = () => {
    window.location.href = 'mailto:info@ingradient.ai';
  };
  

  return (
    <StyledFaq className={`FAQ ${className}`}>
      <div className="container-3">
        <div className="section-title-3">
          <div className="heading-3">FAQs</div>
          <p className="text-3">{text}</p>
        </div>
        <div className="FAQ">
          {/* 첫 번째 질문 */}
          <div className="divider" />
          <div className="title" onClick={() => toggleQuestion(1)}>
            <div className={`question ${questionClassName}`}>{q1}</div>
            <img className="icon" alt="Icon" src={openQuestions[1] ? iconOpened : iconClosed}  />
          </div>
          {openQuestions[1] && (
            <p className="answer">현재 베타서비스 중으로 무료로 서비스를 제공하고 있습니다. 고화질 이미지를 다운로드 받을 수 있는 기능은
            유료 서비스로 현재 준비 중에 있습니다.</p>
          )}
  
          {/* 두 번째 질문 */}
          <div className="divider" />
          <div className="title" onClick={() => toggleQuestion(2)}>
            <div className={`question ${questionClassNameOverride}`}>{q2}</div>
            <img className="icon" alt="Icon" src={openQuestions[2] ? iconOpened : iconClosed}  />
          </div>
          {openQuestions[2] && (
            <p className="answer">유료 서비스를 사용하시면 고화질 이미지를 다운로드 받을 수 있습니다. 아직 유료서비스는 오픈하지 않았으며
            현재 일부 유저들을 대상으로 시범운영 중에 있습니다. 고화질 이미지 생성이 필요하시거나 시범 서비스를
            사용해 보고 싶으신 분은 이메일로 문의주시기 바랍니다. (info@ingraidient.ai)</p>
          )}
  
          {/* 세 번째 질문 */}
          <div className="divider" />
          <div className="title" onClick={() => toggleQuestion(3)}>
            <div className={`question ${divClassName}`}>{q3}</div>
            <img className="icon" alt="Icon" src={openQuestions[3] ? iconOpened : iconClosed}  />
          </div>
          {openQuestions[3] && (
            <p className="answer">⇒ 결제를 하게 되면 이메일로 영수증이 전송됩니다.
            <br />⇒ (예정) 주문내역에서 확인할 수 있습니다.</p>
          )}
  
          {/* 네 번째 질문 */}
          <div className="divider" />
          <div className="title" onClick={() => toggleQuestion(4)}>
            <div className={`question ${divClassNameOverride}`}>{q4}</div>
            <img className="icon" alt="Icon" src={openQuestions[4] ? iconOpened : iconClosed}  />
          </div>
          {openQuestions[4] && (
            <p className="answer">결제 페이지에서 크레딧을 구매하여 사용하실 수 있습니다.</p>
          )}
  
          {/* 다섯 번째 질문 */}
          <div className="divider" />
          <div className="title" onClick={() => toggleQuestion(5)}>
            <div className={`question ${questionClassName1}`}>{q5}</div>
            <img className="icon" alt="Icon" src={openQuestions[5] ? iconOpened : iconClosed}  />
          </div>
          {openQuestions[5] && (
            <p className="answer">1. 회원은 유료서비스 구매 후 서비스를 전혀 사용하지 않은 경우, 결제일로부터 7일 이내에 청약철회를 할 수
            있습니다.
            <br />
            2. 잔여 크레딧에 대한 환불 조항은 제 1항과 동일하게 처리됩니다. 구매 후 서비스를 전혀 사용하지 않은 경우,
            결제일로부터 7일 이내에 청약철회를 할 수 있습니다.
            <br />
            3. 충전된 포인트에 대한 환불 정책 : 위와 동일
            </p>
          )}
        </div>
        <div className="more-question">
          <div className="content-8">
            <div className={`heading-4 ${headingClassName}`}>{moreQuestion}</div>
            <p className="text-4">{moreD}</p>
          </div>
          <Button
            className="button-instance"
            darkMode={false}
            divClassName="design-component-instance-node"
            iconPosition="no-icon"
            small={false}
            buttonStyle="secondary"
            text="Contact"
            onClick={sendEmail}
          />
        </div>
      </div>
    </StyledFaq>
  );
};

Faq.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  icon: PropTypes.string,
  img: PropTypes.string,
  icon1: PropTypes.string,
  icon2: PropTypes.string,
  icon3: PropTypes.string,
  text2: PropTypes.string,
  q1: PropTypes.string,
  q2: PropTypes.string,
  q3: PropTypes.string,
  q4: PropTypes.string,
  q5: PropTypes.string,
};
