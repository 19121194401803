import React, { useEffect } from "react";
import { Layout, Flex } from 'antd';
import styled from 'styled-components';
import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";
import { Gallery } from "../../components/Gallery";
import { Pricing } from "../../components/Pricing";
import { Faq } from "../../components/Faq";
import { Footer } from "../../components/Footer";
import ReactGA from 'react-ga';

const { Content } = Layout;

const StyledHome = styled.div`
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;
`;

const layoutStyle = {
  overflow: 'hidden',
  width: '100%',
};

export const Home = () => {
  const trackPageview = () => {
    ReactGA.pageview('/'); // 실제 경로나 식별자로 변경하세요.
  };

  // 컴포넌트가 마운트될 때 추적 함수 호출
  useEffect(() => {
    trackPageview();
  }, []);

  return (
    <Flex gap="middle" wrap="wrap" > 
      <Layout style={layoutStyle}>
        <Navbar breakpoint="desktop" />
        <Content>
          <StyledHome>
            <Header
              breakpoint="desktop"
              className="design-component-instance-node"
              element="/img/7-1-2.png"
              facebookLogo="/img/facebook-logo-2023-1.png"
              frame="/img/frame-52.svg"
              inbktetqoapibgeabo="/img/in36bktetqoapibgeabo-1.png"
              instagramAdMockup="/img/instagram-ad-mockup-1-1.png"
              instagramLogo="/img/instagram-logo-2022-2.png"
            />
            <Gallery breakpoint="desktop" />
            <Pricing breakpoint="desktop" className="design-component-instance-node" />
            <Faq
              className="design-component-instance-node-2"
              divClassName="FAQ-1"
              divClassNameOverride="FAQ-1"
              headingClassName="FAQ-1"
              iconClosed = "/img/icon-4.svg"
              iconOpened = "/img/icon-1.svg"
              questionClassName="FAQ-1"
              questionClassName1="FAQ-1"
              questionClassNameOverride="FAQ-1"
              text="자주 질문하는 질문들"
              moreQuestion="더 궁금하신 것이 있나요?"
              moreD="아래 Contact 버튼을 클릭 후 문의 주시면 확인하는대로 답 드리겠습니다"
              q1="믹스미는 무료 서비스인가요?"
              q2="고화질 이미지를 생성하기 위해서는 어떻게 해야 하나요?"
              q3="영수증은 어디서 확인하실 수 있습니까?"
              q4="크레딧을 다 사용했을 경우 어떻게 해야 합니까?"
              q5="환불을 받을 수 있습니까?"
            />
            <Footer breakpoint="desktop" className="design-component-instance-node-2" href="mailto:info@ingradient.ai" />
          </StyledHome>
        </Content>
      </Layout>
    </Flex>
  );
};

export default Home;
