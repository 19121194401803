import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledStylePrimarySmallWrapper = styled.div`
  align-items: center;
  background-color: var(--g-black);
  border: 1px solid;
  border-color: #000000;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  padding: 12px 24px;
  position: relative;

  & .button-2 {
    color: var(--g-white);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;

export const StylePrimarySmallWrapper = ({ className, divClassName, text = "Button", onClick }) => {
  return (
    <StyledStylePrimarySmallWrapper className={`style-primary-small-wrapper ${className}`} onClick={onClick}>
      <div className={`button-2 ${divClassName}`}>{text}</div>
    </StyledStylePrimarySmallWrapper>
  );
};

StylePrimarySmallWrapper.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};
