import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Menu, Drawer } from 'antd';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useAuth } from "../../context/AuthContext";
// import { StylePrimarySmall } from "../StylePrimarySmall";

const StyledNavbar = styled.div`
  align-items: center;
  background-color: var(--g-button);
  display: flex;
  flex-direction: row; 
  justify-content: center; 
  height: 88px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 64px;
    padding: 0;
  }

  & .container {
    padding: 0px 64px;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    max-width: 1440px;
    width: 100%;

    
    @media screen and (max-width: 768px) {
      padding: 0px 20px;
    }
  }

  & .consult, & .pricing {
    color: var(--g-white);
    font-family: var(--heading-h6-font-family);
    font-size: var(--heading-h6-font-size);
    font-style: var(--heading-h6-font-style);
    font-weight: var(--heading-h6-font-weight);
    letter-spacing: var(--heading-h6-letter-spacing);
    line-height: var(--heading-h6-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
  }

  & .pricing {
    color: var(--g-line);
  }

  & .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & .logo-name {
      color: #ffffff;
      font-family: "Trispace", Helvetica;
      font-size: 56px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 67.2px;
      position: relative;
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        color: #FFF;
        font-family: Trispace;
        font-size: 26.896px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  
  & .button-begin {
    display: inline-flex;
    gap: 16px;
    align-items: center;

    .ant-btn {
      height: 45px;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    & .login-button {
      background-color: white;
      color: var(--g-button, #006AFF);
      border: none;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;

      @media screen and (max-width: 768px) {
        height: 32px;
        width: 87px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }


    & .start-free-button {
      background-color: black;
      color: var(--g-white, #FFF);
      border: none;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;

      &:hover {
        background-color: #444;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .desktop-menu-items {
      display: none; // 모바일에서 숨김
    }
  }

  @media screen and (min-width: 769px) {
    .desktop-menu-items {
      display: flex;
      gap: 32px;
      padding-left: 0;
      align-items: center;
    }

    .hamburger-menu {
      display: none;
    }
  }
`;

export const Navbar = ({ breakpoint }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { session, signOut } = useAuth();

  console.log("session", session)

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const navigateToLogin = () => {
    window.location.href = 'https://app.mixme.ai/login';
  };

  const navigateToPricing = () => {
    navigate('/');
    setTimeout(() => {
      const pricingSection = document.querySelector('#pricing-section');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
  
  const sendEmail = () => {
    window.location.href = 'mailto:info@ingradient.ai';
  };

  const handleLogout = async () => {
    await signOut();
  };
  
  const menuItems = [
    { label: '상담하기', key: 'item-1', onClick: sendEmail },
    { label: '요금', key: 'item-2', onClick: navigateToPricing }, 
    { label: '무료로 사용하기', key: 'item-3', onClick: navigateToLogin  }, 
  ];

  return (
    <StyledNavbar>
      <div className="container">
        <div className="hamburger-menu">
          <MenuOutlined onClick={toggleDrawer} style={{ fontSize: '24px', color: 'white' }} />
        </div>
        <Drawer title="MixMe" placement="left" onClose={toggleDrawer} open={open}>
          <Menu items={menuItems} mode="vertical" />
        </Drawer>

        <div className="desktop-menu-items">
          <div className="consult" onClick={sendEmail}>상담하기</div>
          <div className="pricing" onClick={navigateToPricing}>요금</div>
        </div>

        <div className="logo">
          <div className="logo-name">mixme</div>
        </div>
        <div className="button-begin" onClick={navigateToLogin}>
          {session && session.user.id !== process.env.REACT_APP_GUEST_USER_ID ? (
            <Button className="login-button" type="primary" shape="round" size={'large'} onClick={handleLogout}>
              로그아웃
            </Button>
          ) : (
            <>
              <Button className="login-button" type="primary" shape="round" size={'large'} onClick={navigateToLogin}>
                로그인
              </Button>
              <Button className="start-free-button" type="primary" shape="round" size={'large'}>
                무료로 시작하기
              </Button>
            </>
          )}
          {/* <Button className="start-free-button" type="primary" shape="round" size={'large'}>
            무료로 시작하기
          </Button> */}
        </div>
      </div>
    </StyledNavbar>
  );
};

Navbar.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop"])
};
