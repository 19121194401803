import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledIconYoutube = styled.svg`
  & .path {
    fill: ${props => props.color};
  }
`;

const IconYoutube = ({ color = "#838A9A", className }) => {
  return (
    <StyledIconYoutube
      className={`icon-youtube ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        className="path"
        d="M21.5914 7.20301C21.4775 6.78041 21.2549 6.39501 20.9457 6.08518C20.6366 5.77534 20.2517 5.55187 19.8294 5.43701C18.2634 5.00701 11.9984 5.00001 11.9984 5.00001C11.9984 5.00001 5.73438 4.99301 4.16738 5.40401C3.7453 5.52415 3.3612 5.75078 3.05194 6.06214C2.74269 6.3735 2.51866 6.75913 2.40138 7.18201C1.98838 8.74801 1.98438 11.996 1.98438 11.996C1.98438 11.996 1.98038 15.26 2.39038 16.81C2.62038 17.667 3.29538 18.344 4.15338 18.575C5.73538 19.005 11.9834 19.012 11.9834 19.012C11.9834 19.012 18.2484 19.019 19.8144 18.609C20.2369 18.4943 20.6221 18.2714 20.932 17.9622C21.2419 17.653 21.4658 17.2682 21.5814 16.846C21.9954 15.281 21.9984 12.034 21.9984 12.034C21.9984 12.034 22.0184 8.76901 21.5914 7.20301ZM9.99438 15.005L9.99938 9.00501L15.2064 12.01L9.99438 15.005Z"
      />
    </StyledIconYoutube>
  );
};

export default IconYoutube;

IconYoutube.propTypes = {
  color: PropTypes.string,
};
