import PropTypes from "prop-types";
import React from 'react';
import { Row, Col } from 'antd';

import styled from "styled-components";

import { DraggableImage } from "../DraggableImage";

const StyledGallery = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // padding: 112px 64px;
  position: relative;
  max-width: 1440px;
  width: 100%;

  & .gallery {
    padding: 12px 64px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 64px 20px;
    }
  }

  & .section-title {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
    padding-bottom: 80px;

    @media (max-width: 768px) {
      padding-bottom: 48px;
      gap: 20px;
    }

    & .heading {
      align-self: stretch;
      color: var(--g-title);
      font-family: var(--heading-h2-font-family);
      font-size: var(--heading-h2-font-size);
      font-style: var(--heading-h2-font-style);
      font-weight: var(--heading-h2-font-weight);
      letter-spacing: var(--heading-h2-letter-spacing);
      line-height: var(--heading-h2-line-height);
      margin-top: -1px;
      position: relative;
      text-align: center;

      @media (max-width: 768px) {
        font-family: var(--heading-h2-mobile-font-family);
        font-size: var(--heading-h2-mobile-font-size);
        font-style: font-family: var(--heading-h2-mobile-font-style);
        font-weight: var(--heading-h2-mobile-font-weight);
        letter-spacing: var(--heading-h2-mobile-letter-spacing);
        line-height: var(--heading-h2-mobile-line-height);
      } 
    }

    & .text {
      align-self: stretch;
      color: var(--g-subtitle);
      font-family: var(--text-large-normal-font-family);
      font-size: var(--text-large-normal-font-size);
      font-style: var(--text-large-normal-font-style);
      font-weight: var(--text-large-normal-font-weight);
      letter-spacing: var(--text-large-normal-letter-spacing);
      line-height: var(--text-large-normal-line-height);
      position: relative;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  & .gallery-image {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  & .image-description {
    color: var(--g-subtitle);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
`;


export const Gallery = ({ breakpoint }) => {
  return (
    <StyledGallery>
      <div className="gallery">
        <div className="section-title">
          <div className="heading">mixme 생성 이미지</div>
          <p className="text">모든 이미지는 mixme가 생성한 무보정 제품 배경 이미지입니다.</p>
        </div>
        <Row gutter={[32, 32]} wrap>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <div className="gallery-image">
              <DraggableImage 
                imageSrc="/img/gallery/1-2 1.png" 
                overlayImageSrc="/img/gallery/1-2 4.png"
              />
              <div className="image-description">핸드백 / 심플스타일</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <div className="gallery-image">
              <DraggableImage 
                imageSrc="/img/gallery/3-1 1.png" 
                overlayImageSrc="/img/gallery/3-2 2.png"
              />
              <div className="image-description">커피빈 / 심플스타일</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <div className="gallery-image">
              <DraggableImage 
                imageSrc="/img/gallery/4-1 1.png" 
                overlayImageSrc="/img/gallery/4-2 2.png"
              />
              <div className="image-description">커피빈 / 심플스타일</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <div className="gallery-image">
              <DraggableImage 
                imageSrc="/img/gallery/5-1 1.png" 
                overlayImageSrc="/img/gallery/5-2 2.png"
              />
              <div className="image-description">향수 / 자연 배경 스타일</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <div className="gallery-image">
              <DraggableImage 
                imageSrc="/img/gallery/6-1 1.png" 
                overlayImageSrc="/img/gallery/8-2 2.png"
              />
              <div className="image-description">마카롱 / 고급 스타일</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
            <div className="gallery-image">
              <DraggableImage 
                imageSrc="/img/gallery/7-1 1.png" 
                overlayImageSrc="/img/gallery/9-2 2.png"
              />
              <div className="image-description">신발 / 자연 배경 스타일</div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledGallery>
  );
};

Gallery.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop"])
};