import React from 'react';
import ReactDOMClient from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';
import { Home } from "./pages/Home";
import { Terms } from "./pages/Terms";
import { Policy } from "./pages/Policy";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./context/AuthContext";
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';


ReactGA.initialize(process.env.REACT_APP_GA_ID);
TagManager.initialize({ gtmId: process.env.REACT_APP_TM_ID });

const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(muiLicenseKey);

const app = document.getElementById('app');
const root = ReactDOMClient.createRoot(app);
root.render(
  <AuthProvider>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
