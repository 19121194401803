import PropTypes from "prop-types";
import React from 'react';
import { StylePrimarySmallWrapper } from "../StylePrimarySmallWrapper";
import { Row, Col } from 'antd';
// import { Iamport, Request, Enum } from 'iamport-rest-client-nodejs';
import styled from "styled-components";


const StyledPricing = styled.div`
  align-items: center;
  background-color: var(--g-lighter-grey);
  display: flex;
  flex-direction: column;
  gap: 80px;
  position: relative;
  max-width: 1440px;
  width: 100%;

  & .price-container {
    padding: 112px 64px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 112px 20px;
    }
  }

  & .content-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
    width: 100%;
  }

  & .section-title {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
    justify-content: center;
    padding: 0 0 80px 0;

    @media (max-width: 768px) {
      padding: 0 0 48px 0;
    }
  }

  & .content-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
    justify-content: center;
    padding: 0 80px;
  }

  & .heading-2 {
    align-self: stretch;
    color: var(--g-title);
    font-family: var(--heading-h2-font-family);
    font-size: var(--heading-h2-font-size);
    font-style: var(--heading-h2-font-style);
    font-weight: var(--heading-h2-font-weight);
    letter-spacing: var(--heading-h2-letter-spacing);
    line-height: var(--heading-h2-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }

  & .price-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
    max-width: 432px;
    height: 632px;
    margin: 0 auto;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  & .price-card-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
    max-width: 432px;
    height: 632px;
    margin: 0 auto;

    // @media (max-width: 768px) {
    //   height: auto;
    // }
  }

  & .price-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-white);
    border: 1px solid;
    border-color: var(--g-line);
    border-radius: 16px;
    box-shadow: var(--large);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    padding: 32px;
    position: relative;
  }

  & .price {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
      gap: 24px;
    }
  }

  & .price-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  & .container-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
  }

  & .price-3 {
    color: var(--g-title);
    font-family: var(--heading-h1-font-family);
    font-size: var(--heading-h1-font-size);
    font-style: var(--heading-h1-font-style);
    font-weight: var(--heading-h1-font-weight);
    letter-spacing: var(--heading-h1-letter-spacing);
    line-height: var(--heading-h1-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;

    @media (max-width: 768px) {
      font-family: var(--heading-h1-mobile-font-family);
      font-size: var(--heading-h1-mobile-font-size);
      font-style: var(--heading-h1-mobile-font-style);
      font-weight: var(--heading-h1-mobile-font-weight);
      letter-spacing: var(--heading-h1-mobile-letter-spacing);
      line-height: var(--heading-h1-mobile-line-height);
    } 
  }

  & .list {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  & .what-provides {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  & .list-item {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }

  & .check {
    height: 24px;
    position: relative;
    width: 24px;
  }

  & .text-2 {
    color: var(--g-title);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .style-primary-small-false-dark-mode-false-icon-position-no-icon {
    align-self: stretch !important;
    background-color: unset !important;
    border-color: var(--g-button) !important;
    border-radius: 48px !important;
    display: flex !important;
    flex: 0 0 auto !important;
    margin-bottom: -1px !important;
    margin-left: -1px !important;
    margin-right: -1px !important;
    width: 100% !important;
    cursor: pointer;
  }

  & .style-primary-small-false-dark-mode-false-icon-position-no-icon-instance {
    color: var(--g-button) !important;
    font-family: var(--heading-h6-font-family) !important;
    font-size: var(--heading-h6-font-size) !important;
    font-style: var(--heading-h6-font-style) !important;
    font-weight: var(--heading-h6-font-weight) !important;
    letter-spacing: var(--heading-h6-letter-spacing) !important;
    line-height: var(--heading-h6-line-height) !important;
  }

  & .price-button {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 210px;
    position: relative;
    width: 100%;
  }

  & .button-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-button);
    border-radius: 48px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 12px 24px;
    position: relative;
    width: 100%;
  }

  & .button-3 {
    color: var(--g-white);
    font-family: "Pretendard-Bold", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    width: fit-content;
  }

  & .span {
    font-family: var(--heading-h6-font-family);
    font-size: var(--heading-h6-font-size);
    font-style: var(--heading-h6-font-style);
    font-weight: var(--heading-h6-font-weight);
    letter-spacing: var(--heading-h6-letter-spacing);
    line-height: var(--heading-h6-line-height);

    @media (max-width: 768px) {
      font-family: var(--heading-h6-mobile-font-family);
      font-size: var(--heading-h6-mobile-font-size);
      font-style: var(--heading-h6-mobile-font-style);
      font-weight: var(--heading-h6-mobile-font-weight);
      letter-spacing: var(--heading-h6-mobile-letter-spacing);
      line-height: var(--heading-h6-mobile-line-height);
    }
  }

  & .text-wrapper-12 {
    font-family: var(--text-large-normal-font-family);
    font-size: var(--text-large-normal-font-size);
    font-style: var(--text-large-normal-font-style);
    font-weight: var(--text-large-normal-font-weight);
    letter-spacing: var(--text-large-normal-letter-spacing);
    line-height: var(--text-large-normal-line-height);
    cursor: pointer;

    @media (max-width: 768px) {

      font-family: var(--heading-h6-mobile-font-family);
      font-size: var(--heading-h6-mobile-font-size);
      font-style: var(--heading-h6-mobile-font-style);
      font-weight: 400;
      letter-spacing: var(--heading-h6-mobile-letter-spacing);
      line-height: var(--heading-h6-mobile-line-height);

      display: flex;
      flex-direction: column;

      &::before {
        content: ' ';
        display: block;
      }

      & > span:first-child {
        display: block;
        margin-bottom: 8px; // Adjust space as needed
      }
    }
  }
  
  & .splitter {
    font-family: var(--text-large-normal-font-family);
    font-size: var(--text-large-normal-font-size);
    font-style: var(--text-large-normal-font-style);
    font-weight: var(--text-large-normal-font-weight);
    letter-spacing: var(--text-large-normal-letter-spacing);
    line-height: var(--text-large-normal-line-height);

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Pricing = ({ breakpoint, className }) => {
  const navigateToStartFree = () => {
    window.location.href = 'https://app.mixme.ai';
  };

  const navigateToWallet = () => {
    window.location.href = 'https://app.mixme.ai/wallet';
  };

  return (
    <StyledPricing className={`pricing ${className}`}>
      <div className="price-container" id="pricing-section"> 
        <div className="section-title">
          <div className="heading-2">요금 안내</div>
        </div>
        <Row gutter={[32, 32]} wrap>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className="price-card">
              <div className="price-wrapper">
                <div className="price">
                  <div className="price-2">
                    <div className="container-2">
                      <div className="price-3">무료</div>
                    </div>
                  </div>
                  <div className="list">
                    <div className="what-provides">
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">저화질 이미지</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">워터마크 표시</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">기본 템플릿</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">기본 프롬프트</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">이메일 상담</div>
                      </div>
                    </div>
                    <StylePrimarySmallWrapper
                      className="style-primary-small-false-dark-mode-false-icon-position-no-icon"
                      divClassName="style-primary-small-false-dark-mode-false-icon-position-no-icon-instance"
                      text="무료로 지금 시작하기"
                      onClick={navigateToStartFree} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className="price-card-2">
              <div className="price-wrapper">
                <div className="price">
                  <div className="price-2">
                    <div className="container-2">
                      <div className="price-3">크레딧 충전</div>
                    </div>
                  </div>
                  <div className="list">
                    <div className="what-provides">
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">HD 이미지 제공</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">워터마크 표시 제거</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">템플릿 추가 제공</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">프롬프트 추가 제공</div>
                      </div>
                      <div className="list-item">
                        <img className="check" alt="Check" src="/img/check-10.svg" />
                        <div className="text-2">채팅 상담</div>
                      </div>
                    </div>
                    <div className="price-button">
                      <div className="button-wrapper">
                        <p className="button-3">
                          <span className="span">9900원 </span>
                          <span className="splitter">| </span>
                          <span className="text-wrapper-12" onClick={navigateToWallet}>
                            20 credit (장당 495원)
                          </span>
                        </p>
                      </div>
                      <div className="button-wrapper">
                        <p className="button-3">
                          <span className="span">34,900원 </span>
                          <span className="splitter">| </span>
                          <span className="text-wrapper-12" onClick={navigateToWallet}>
                            100 credit (장당 349원)
                          </span>
                        </p>
                      </div>
                      <div className="button-wrapper">
                        <p className="button-3">
                          <span className="span">99,000원 </span>
                          <span className="splitter">| </span>
                          <span className="text-wrapper-12" onClick={navigateToWallet}>
                            500 credit (1장당 198원)
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledPricing>
  );
};

Pricing.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop"]),
};
