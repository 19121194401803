import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from 'react-router-dom'; 
import { Row, Col } from 'antd';
import { IconFacebook } from "../../icons/IconFacebook";
import { IconInstagram } from "../../icons/IconInstagram";
import { IconX } from "../../icons/IconX";
import { IconYoutube } from "../../icons/IconYoutube";
import styled from "styled-components";

const StyledFooter = styled.div`
  align-items: center;
  background-color: var(--g-lighter-grey);
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 80px 64px;
  position: relative;
  max-width: 1440px;
  width: 100%;

  & .content-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    position: relative;
    width: 100%;
  }

  & .logo-footer {
    color: var(--g-button);
    font-family: "Trispace", Helvetica;
    font-size: 42.2px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 50.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .footer-informations {
    // align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    // gap: 32px;
    position: relative;
    width: 100%;
  }

  & .company-informations {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
    width: 100%;
    max-width: 640px;
  }

  & .business-first {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }

  & .business-name {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    max-width: 320px;
    width: 100%;
  }

  & .info-title {
    align-self: stretch;
    color: var(--g-black);
    font-family: var(--text-regular-semi-bold-font-family);
    font-size: var(--text-regular-semi-bold-font-size);
    font-style: var(--text-regular-semi-bold-font-style);
    font-weight: var(--text-regular-semi-bold-font-weight);
    letter-spacing: var(--text-regular-semi-bold-letter-spacing);
    line-height: var(--text-regular-semi-bold-line-height);
    margin-top: -1px;
    position: relative;
  }

  & .info-answer {
    align-self: stretch;
    color: var(--g-black);
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
  }

  & .business-owner {
    align-items: flex-start;
    display: flex;
    // flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    position: relative;
    max-width: 144px;
    width: 100%;
  }

  & .container-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  & .text-wrapper-18 {
    align-self: stretch;
    color: var(--g-black);
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-decoration: underline;
  }

  & .social-links {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
  }

  & .icon-instance-node {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  & .footer-action {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 24px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }

  & .link-list {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
    position: relative;
  }

  & .consult {
    align-self: stretch;
    color: var(--g-button);
    font-family: var(--heading-h5-font-family);
    font-size: var(--heading-h5-font-size);
    font-style: var(--heading-h5-font-style);
    font-weight: var(--heading-h5-font-weight);
    letter-spacing: var(--heading-h5-letter-spacing);
    line-height: var(--heading-h5-line-height);
    margin-top: -1px;
    position: relative;
    cursor: pointer;
  }

  & .price-using {
    align-self: stretch;
    color: var(--g-title);
    font-family: var(--heading-h5-font-family);
    font-size: var(--heading-h5-font-size);
    font-style: var(--heading-h5-font-style);
    font-weight: var(--heading-h5-font-weight);
    letter-spacing: var(--heading-h5-letter-spacing);
    line-height: var(--heading-h5-line-height);
    position: relative;
    cursor: pointer;
  }

  & .consult, & .price-using {
    text-align: right;
  
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  
  & .credits {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }

  & .divider-2 {
    align-self: stretch;
    background-color: var(--g-line);
    height: 1px;
    position: relative;
    width: 100%;
  }

  & .last-info {
    width: 100%;
  }

  & .copy-right {
    color: var(--g-title);
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .footer-links {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  & .footer-links {
    justify-content: flex-end;
  
    @media (max-width: 991px) {
      justify-content: flex-start;
    }
  }

  & .extra-info {
    color: var(--g-subtitle);
    font-family: var(--text-small-link-font-family);
    font-size: var(--text-small-link-font-size);
    font-style: var(--text-small-link-font-style);
    font-weight: var(--text-small-link-font-weight);
    letter-spacing: var(--text-small-link-letter-spacing);
    line-height: var(--text-small-link-line-height);
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
  }

  & .footer-content {
    width: 100%;
  }
`;

const Footer = ({ breakpoint, className, href }) => {
  const navigate = useNavigate(); 

  const navigateToPricing = () => {
    navigate('/');
    setTimeout(() => {
      const pricingSection = document.querySelector('#pricing-section');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
  
  const sendEmail = () => {
    window.location.href = 'mailto:info@ingradient.ai';
  };
  
  const handleNavigation = (path) => {
    navigate(path);
  };

  const navigateToApp = () => {
    window.location.href = 'https://app.mixme.ai/';
  };

  return (
    <StyledFooter className={`footer ${className}`}>
      <div className="content-9">
        <div className="logo-footer">mixme</div>
      </div>
      <div className="footer-content">
        <Row gutter={[32, 32]}>
        <Col xs={{span:24, order:2}} sm={{span:24, order:2}} md={{span:24, order:2}} lg={{span:15, order:1}}>
            <div className="company-informations">
              <Row gutter={[32, 32]}>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={6}>
                      <div className="business-first">
                        <div className="business-name">
                          <div className="info-title">상호명</div>
                          <div className="info-answer">인그래디언트 주식회사</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <div className="business-owner">
                        <div className="info-title">대표자명</div>
                        <div className="info-answer">이준호</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <div className="business-owner">
                        <div className="info-title">사업자등록번호</div>
                        <div className="info-answer">518-88-01508</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <div className="business-owner">
                        <div className="info-title">통신판매업신고번호</div>
                        <div className="info-answer">2023-서울강남-04017</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12}>
                      <div className="business-first">
                        <div className="business-name">
                          <div className="info-title">사업장주소지</div>
                          <p className="info-answer">서울특별시 강남구 강남대로 364번지, 1525호</p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <div className="business-owner">
                        <div className="info-title">전화번호</div>
                        <div className="info-answer">02-6959-0503</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <div className="business-owner">
                        <div className="info-title">문의</div>
                        <div className="container-4">
                          <a className="text-wrapper-18" href={href} rel="noopener noreferrer" target="_blank">
                            info@ingradient.ai
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <div className="social-links">
                    <IconFacebook className="icon-instance-node" color="#838A9A" />
                    <IconInstagram className="icon-instance-node" color="#838A9A" />
                    <IconX className="icon-instance-node" color="#838A9A" />
                    <IconYoutube className="icon-instance-node" color="#838A9A" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={{span:24, order:1}} sm={{span:24, order:1}} md={{span:24, order:1}} lg={{span:9, order:2}}>
            <div className="footer-action">
              <div className="link-list">
                <div className="consult" onClick={sendEmail}>상담하기</div>
                <div className="price-using" onClick={navigateToPricing}>요금</div>
                <div className="price-using" onClick={navigateToApp}>무료로 사용하기</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="credits">
        <div className="divider-2" />
        <div className="last-info">
          <Row gutter={[32, 32]}>
            <Col xs={{span:24, order:2}} sm={{span:24, order:2}} md={{span:12, order:1}} lg={{span:12, order:1}}>
              <p className="copy-right">© 2023 mixme. All rights reserved.</p>
            </Col>
            <Col xs={{span:24, order:1}} sm={{span:24, order:1}} md={{span:12, order:2}} lg={{span:12, order:2}}>
              <div className="footer-links">
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="extra-info" onClick={() => handleNavigation('/policy')}>Privacy Policy</div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="extra-info" onClick={() => handleNavigation('/terms')}>Terms of Service</div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="extra-info">Cookies Settings</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </StyledFooter>
  );
};

Footer.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop"]),
  href: PropTypes.string,
};

export default Footer;