import React from "react";
import "./style.css";

export const Terms = () => {
  return (
    <div className="terms-of-service">
      <div className="frame">
        <div className="div">
          <div className="text-wrapper">믹스미 서비스 이용약관</div>
          <div className="text-wrapper-2">적용 일자: 2023-12-19</div>
        </div>
        <div className="frame-2">
          <p className="element">
            인그래디언트(이하 &#34;회사&#34;)은(는) 믹스미을(를) 제공함에 있어 정보주체의 자유와 권리 보호를 위해
            「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고
            있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고,
            이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
            수립공개합니다.
          </p>
          <div className="text-wrapper-3">제1조 (목적)</div>
          <div className="div">
            <div className="text-wrapper-4">제1조 (목적)</div>
            <p className="p">
              본 약관은 인그래디언트 주식회사(이하 ‘회사’)가 제공하는 믹스미(mixme) 및 관련 부가 서비스(총칭하여
              ‘서비스’라 함)의 사용 조건, 절차, 기타 필요한 사항들을 정하는 것을 목표로 합니다. 이 약관에 동의하는
              것만으로, 해당 서비스들의 별도 계약 없이도 이용 가능하게 됩니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제2조 (용어설명)</div>
            <p className="p">
              본 약관에서 사용되는 용어의 정의는 아래와 같습니다.
              <br />
              1. “웹사이트”라 함은 서비스 제공을 위해 회사가 운영하는 웹사이트 “https://*.mixme.ai”를 의미합니다.
              <br />
              2. “회원”이라 함은 “회사”의 “서비스”에 접속하여 본 약관에 따라 “회사”와 이용계약을 체결하고 “회사”가 제공하는
              “서비스”를 이용하는 이용자를 말합니다.
              <br />
              3. “아이디(ID)”라 함은 “회원”의 식별과 “서비스” 이용을 위하여 “회원”이 정하고 “회사”가 승인하는 문자와 숫자의
              조합을 의미합니다.
              <br />
              4. “비밀번호”라 함은 “회원”이 설정한 “아이디(ID)”와 일치되는 회원임을 확인하고 비밀 보호를 위해 회원 자신이
              정한 문자 또는 숫자의 조합을 의미합니다.
              <br />
              5. “회원의 정보”라 함은 회원이 “회사”의 “서비스”에 저장하는 정보(『국가정보화기본법』 제3조 제1호에 따른
              정보)로써 개인정보를 포함한 회원이 소유 또는 관리하는 모든 정보를 의미합니다.
              <br />
              6. “콘텐츠”라 함은 회사가 제공하는 서비스 내의 부호, 문자, 음성, 사운드, 이미지, 영상 등 다양한 형태의
              정보와, 이를 이용하여 회원이 서비스 내에서 생성하는 결과물을 의미합니다.
              <br />
              7. “크레딧”이라 함은 회사 내에서 사용되는 유료재화를 의미하며 해당 재화를 이용하여 다양한 유료 서비스를
              사용할 수 있습니다.
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-4">제3조 (약관의 공개 및 변경)</p>
            <p className="p">
              1. 회사는 이용약관을 웹사이트 하단에 링크를 통해 게제하며 연결화면을 통해 확인할 수 있습니다.
              <br />
              2. 회원이 본 약관 내용에 동의하는 경우, 회사의 서비스 제공 행위 및 회원의 서비스 사용 행위에 본 약관이
              우선적으로 적용됩니다.
              <br />
              3. 회사는 관련 법률을 준수하는 범위 내에서 약관을 수정할 수 있습니다.
              <br />
              4. 회사가 약관을 변경할 경우, 변경 이유와 적용 일자를 명시하여 현행 약관과 함께 적용 일자 7일 이전부터 적용
              일자 전일까지 공지합니다. 변경이 회원에게 불리한 경우, 최소 30일 이전에 공지합니다.
              <br />
              5. 회사가 공지한 적용 일자까지 회원이 이용계약 해지 의사를 표명하지 않으면, 변경된 약관에 동의한 것으로
              간주합니다.
              <br />
              6. 약관에서 명시하지 않은 사항은 관련 법률 및 상관례에 따릅니다.
              <br />
              7. 회사는 서비스 이용 시 범죄 예방과 사회 윤리 준수를 위한 윤리 지침을 게시할 수 있으며, 이는 약관의 일부로
              간주합니다. 회원은 약관에 동의함으로써 윤리 지침의 준수에도 동의하는 것으로 봅니다.
            </p>
          </div>
          <div className="text-wrapper-3">제 2장 서비스 이용계약</div>
          <div className="div">
            <div className="text-wrapper-4">제4조 (이용계약의 성립)</div>
            <p className="p">
              1. 이용 계약은 법인 및 개인 신청자가 온라인으로 회사에서 제공하는 소정의 회원가입 신청 양식에서 요구하는
              사항을 기록하고 회사가 이를 승낙함으로써 성립됩니다.
              <br />
              2. 회사는 다음 각 호에 해당하는 이용 계약에 대하여는 서비스 이용 신청 승인을 거부하거나 연기할 수 있으며 또는
              서비스 계약을 해지할 수 있습니다.
              <br />&nbsp;&nbsp;① 다른 사람의 명의나 신청인이 불법적으로 가명을 사용하여 신청하였을 때
              <br />&nbsp;&nbsp;② 허위의 정보를 기재하거나 회사가 기재를 요구한 내용을 기재하지 않은 경우
              <br />&nbsp;&nbsp;③ 신청인이 법률 위반 또는 불법 행위 등 부정한 용도로 서비스를 이용하려 하는 경우
              <br />&nbsp;&nbsp;④ 회사를 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우
              <br />&nbsp;&nbsp;⑤ 기타 회사에서 정한 규정과 기준에 맞지 않는 경우
              <br />
              3. 회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
            </p>
          </div>
          <p className="text-wrapper-3">제 3장 계약 당사자 의무</p>
          <div className="div">
            <div className="text-wrapper-4">제5조 (회사의 의무)</div>
            <p className="p">
              1. 회사는 법률 및 본 약관을 위반하지 않으며, 안정적이고 지속적인 상품 및 서비스 제공에 최선을 다합니다.
              <br />
              2. 회사는 회원의 안전한 서비스 이용을 위해 보안 시스템을 갖추고, 개인정보 처리방침을 준수합니다.
              <br />
              3. 회원의 합리적인 의견이나 불만사항에 대해서는 즉시 처리합니다. 즉시 처리가 어려운 경우에는 사유와 처리
              일정을 회원에게 통보합니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제6조 (회원의 의무)</div>
            <p className="p">
              1. 회원은 이 약관 및 회사의 공지사항, 서비스 이용 안내 등을 숙지하고 준수해야 합니다. 다음 행위는 금지됩니다:
              <br />&nbsp;&nbsp;• 회사의 동의 없는 영리 활동, 광고, 정치활동
              <br />&nbsp;&nbsp;• 회사 서비스를 통한 정보의 무단 이용, 복제, 유통
              <br />&nbsp;&nbsp;• 타인의 지적재산권 침해
              <br />&nbsp;&nbsp;• 회사 및 제3자의 명예 훼손, 업무 방해
              <br />&nbsp;&nbsp;• 불법적 또는 부당한 행위
              <br />&nbsp;&nbsp;• 타 회원의 개인정보 무단 수집, 저장, 게시, 유포
              <br />&nbsp;&nbsp;• 리버스 엔지니어링, 디컴파일, 디스어셈블 등을 통한 서비스의 변형
              <br />&nbsp;&nbsp;• 이용 신청 또는 변경 시 허위 내용 기재
              <br />&nbsp;&nbsp;• 타인의 정보 도용
              <br />&nbsp;&nbsp;• 회사의 정보 변경 또는 서비스 장애 유발
              <br />&nbsp;&nbsp;• 해킹, 자동 접속 프로그램 사용 등으로 서비스 방해
              <br />&nbsp;&nbsp;• 계정 대여, 양도 또는 타인 사용 허용
              <br />&nbsp;&nbsp;• 금지된 정보의 송신, 게시
              <br />&nbsp;&nbsp;• 도박, 음란, 사회질서 저해 행위
              <br />2. 회원은 아이디, 비밀번호, 결제 수단 정보 관리 책임을 집니다. 관리 소홀로 인한 문제에 대해 회사는 책임지지
              않습니다.
            </p>
          </div>
          <p className="text-wrapper-3">제 4장 서비스 제공 및 이용</p>
          <div className="div">
            <div className="text-wrapper-4">제7조 (서비스 이용)</div>
            <p className="p">
              1. 회원은 이 약관에 정해진 사항을 준수하며 서비스를 이용할 권리가 있습니다. 회사는 회원의 이용 신청을
              승낙함으로써, 회원은 서비스를 이용할 수 있게 됩니다.
              <br />
              2. 서비스의 이용 시간은 원칙적으로 연중무휴, 1일 24시간을 기준으로 합니다. 그러나 회사의 업무나 기술상 필요에
              의해 서비스 이용이 일시적으로 중단될 수 있으며, 이러한 경우 회사는 사전에 이를 공지합니다. 불가피한 사유로
              사전 공지가 어려운 경우에는 사후에 공지할 수 있습니다.
              <br />
              3. 회사는 서비스 이용에 필요한 경우 회원의 서비스 이용 가능한 기기 종류나 기기 대 수 등을 설정하거나 변경할
              수 있습니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제8조 (서비스 중지)</div>
            <p className="p">
              1. 회사는 다음과 같은 경우 서비스의 전부 또는 일부를 일시 중단할 수 있습니다:
              <br />&nbsp;&nbsp;• 통신사업자의 서비스 중단, 정전, 설비의 이전·보수·공사 등으로 서비스 제공이 어려운 경우
              <br />&nbsp;&nbsp;• 서비스 장비의 장애, 서비스 폭주 등으로 정상적인 서비스가 어려운 경우
              <br />&nbsp;&nbsp;• 전시, 천재지변, 국가비상사태 등 불가항력적 사유 발생 시<br />
              회사가 이용하는 서버의 문제나 외부 업체의 사유로 서비스 제공이 어려운 경우
              <br />&nbsp;&nbsp;• 서비스의 정기점검 및 긴급점검
              <br />
              2. 회사는 정기점검이 필요한 경우 이를 실시할 수 있으며, 점검 시간은 사전에 공지합니다. 긴급 점검이 필요한
              경우에는 점검 시간을 서비스 내에 사후 공지할 수 있습니다.
              <br />
              3. 회사는 서비스 제공 중단으로 인한 손해에 대하여 배상합니다, 단, 제1항의 사유 또는 회사의 고의·과실이 없는
              경우에는 그러하지 않습니다.
              <br />
              4. 회사는 영업의 폐지, 콘텐츠 제공 계약 만료, 서비스의 수익 악화 등 경영상의 중대한 사유로 서비스를 중단할 수
              있습니다. 이 경우, 중단일자 30일 이전까지 이를 공지하고 회원에게 통지합니다.
            </p>
          </div>
          <div className="div">
            <p className="text-wrapper-4">제9조 (회원 탈퇴 및 자격상실)</p>
            <p className="p">
              1. 회원은 언제든지 회사에 탈퇴를 요청할 수 있으며, 회사는 즉시 회원 탈퇴를 처리합니다.
              <br />
              2. 회원이 다음과 같은 행위를 한 경우, 회사는 회원 자격을 제한, 정지시키거나 이용계약을 해지할 수 있습니다:
              <br />&nbsp;&nbsp;• 가입 신청 시 허위 내용을 등록한 경우
              <br />&nbsp;&nbsp;• 서비스 이용 대금, 기타 채무 불이행
              <br />&nbsp;&nbsp;• 다른 사람의 서비스 이용을 방해하거나 정보를 도용하는 경우
              <br />&nbsp;&nbsp;• 법령 또는 이 약관에 위반하여 행위를 하는 경우
              <br />
              3. 회사는 위와 같은 이유로 회원 자격을 제한·정지 시킬 때, 해당 사유와 조치 내용을 회원에게 통지합니다.
              <br />
              4. 회원 자격 제한·정지 후 동일한 행위가 반복되거나 시정되지 않는 경우, 회사는 회원 자격을 상실시킬 수
              있습니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제10조 (정보의 제공)</div>
            <p className="p">
              1. 회사는 회원에게 서비스 이용에 필요한 다양한 정보를 전자메일, 문자메시지 등의 방법으로 제공할 수 있습니다.
              <br />
              2. 회사는 광고성 정보를 제공하는 경우, 회원의 수신 동의를 받습니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제11조 (광고게제)</div>
            <p className="p">
              1. 회사는 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다.
              <br />
              2. 회사의 서비스를 통해 제3자가 제공하는 광고나 서비스에 연결될 수 있습니다. 이 경우 해당 서비스는 회사의
              책임 하에 있지 않으며, 관련하여 발생하는 손해에 대해 회사는 책임지지 않습니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제12조 (게시물관리)</div>
            <p className="p">
              1. 회사는 불법적이거나 AI 윤리 규정에 위배되는 콘텐츠를 감시할 수 있으며, 필요한 경우 해당 콘텐츠를
              삭제하거나 게시를 거부할 수 있습니다.
              <br />
              2. 회사는 모든 콘텐츠를 검토하지는 않으므로, 콘텐츠의 적법성 및 적합성에 대한 책임은 회원에게 있습니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제13조 (저작권 등의 귀속)</div>
            <p className="p">
              1. 회원이 생성한 콘텐츠에 대한 모든 지적 재산권은 회원에게 속합니다.
              <br />
              2. 회사는 서비스의 운영, 개선, 연구 목적, 서비스 내 노출, 서비스 홍보 등을 위해 회원이 생성한 콘텐츠를 사용할
              수 있으며 그 과정 중에 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차 저작물 작성의 방식 등으로 활용될 수
              있습니다.
              <br />
              3. 회사는 서비스 이용 과정에서 회원이 제공한 콘텐츠를 다른 목적으로 사용하지 않습니다.
              <br />
              4. 회원은 자신의 콘텐츠가 제3자의 권리를 침해하지 않음을 보증하며, 이를 위반하여 발생하는 모든 책임은
              회원에게 있습니다.
              <br />5. 이 조항은 회원이 서비스를 탈퇴 한 이후에도 유효하게 적용됩니다.
            </p>
          </div>
          <p className="text-wrapper-3">제 5장 주문 및 결제</p>
          <div className="div">
            <div className="text-wrapper-4">제14조 (대금결제)</div>
            <p className="p">
              1. 회원은 유료서비스에 대한 결제 시 본 약관 및 구매 조건에 따라 이용계약이 성립하며, 이에 따라 요금이
              결제됩니다.
              <br />
              2. 회원은 다양한 카드 결제 수단을 이용할 수 있으며, 회사는 추가 수수료를 부과하지 않습니다.
              <br />
              3. 회원이 결제한 유료서비스는 결제 완료 시부터 시작됩니다.
              <br />
              4. 회원은 유료서비스 이용료는 일시 지급과 자동결제 방식으로 나누어지며 자동결제 방식을 선택했을 시 이용료는
              매월 자동 결제되며, 이용 기간은 자동으로 갱신됩니다.
              <br />
              5. 회원이 유료서비스를 상위 서비스로 변경할 경우, 새로운 서비스 요금이 부과되며, 기존 서비스 요금은 환불되지
              않습니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제15조 (청약철회 및 환불)</div>
            <p className="p">
              1. 회원은 유료서비스 구매 후 서비스를 전혀 사용하지 않은 경우, 결제일로부터 7일 이내에 청약철회를 할 수
              있습니다.
              <br />
              2. 잔여 크레딧에 대한 환불 조항은 제 1항과 동일하게 처리됩니다. 구매 후 서비스를 전혀 사용하지 않은 경우, 결제일로부터 7일 이내에 청약철회를 할 수 있습니다. 잔여 크레딧 환불은 결제가 되었던 수단으로 진행 됩니다. 
              <br />
              3. 청약철회가 법률적으로 제한되는 서비스의 경우, 회사는 관련 법령에 따라 조치를 취합니다.
              <br />
              4. 회원은 구매한 유료 콘텐츠의 내용이 광고 내용과 다를 경우, 콘텐츠 이용 가능일로부터 3개월 이내, 또는 그 사실을 알았거나 알 수 있었던 날로부터 30일 이내에 청약철회를 할 수 있습니다.
              <br />
              5. 회사는 청약철회 시 회원의 결제 내역을 검토하고 필요한 경우 거래를 중지하거나 취소할 수 있습니다.
            </p>
          </div>
          <p className="text-wrapper-3">제 6장 AI 윤리 규정</p>
          <div className="div">
            <div className="text-wrapper-4">제16조 (회사의 의무)</div>
            <p className="p">
              1. 회사는 AI 윤리 규정을 준수하며, 서비스 사용 시 발생할 수 있는 문제를 예방하기 위한 목적으로 규정을
              설정합니다.
              <br />
              2. 회사는 사용자에게 유용하고 친근한 AI 서비스를 제공하는 것을 지향하며, 편향된 정보의 도출을 방지하기 위해
              노력합니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제17조 (회원의 의무)</div>
            <p className="p">
              1. 회원은 서비스를 악의적으로 사용하거나 서비스의 평판에 위험을 초래하는 행위를 해서는 안 됩니다.
              <br />
              2. 회원은 서비스 이용 과정에서 발생한 AI 결과물을 적절하게 사용하며, 관련하여 법적∙윤리적 문제가 발생하면
              이에 대한 책임을 집니다.
              <br />
              3. 회원은 서비스 사용 중 문제가 되거나 될 수 있는 결과물에 대해 즉시 회사에 알려야 하며, 이를 통해 서비스
              개선에 협조해야 합니다.
            </p>
          </div>
          <div className="text-wrapper-3">제 7장 기타사항</div>
          <div className="div">
            <div className="text-wrapper-4">제18조 (면책 및 손해배상)</div>
            <p className="p">
              1. 회사는 약관 위반으로 인한 손해에 대해 책임을 집니다. 단, 고의나 과실이 없는 경우에는 책임지지 않습니다.
              <br />
              2. 회사는 다음과 같은 경우에 발생한 손해에 대해서는 책임을 지지 않습니다:
              <br />&nbsp;&nbsp;• 서비스용 설비의 보수, 교체 등으로 인한 서비스 중단
              <br />&nbsp;&nbsp;• 회원의 고의 또는 과실로 인한 서비스 장애
              <br />&nbsp;&nbsp;• 무료로 제공되는 서비스의 이용으로 인한 손해
              <br />&nbsp;&nbsp;• 회원 계정정보의 유출로 인한 손해
              <br />&nbsp;&nbsp;• 기타 회사의 귀책사유가 없는 경우
              <br />
              3. 회사는 AI 결과값의 적법성, 정확성 등을 보장하지 않으며, 이로 인한 손해에 대해서는 책임지지 않습니다.
              <br />
              4. 서비스 이용을 통해 생성된 결과물을 이용하여 발생된 모든 문제에 대한 책임은 회원에게 있으며 이에 대해
              회사는 일체 책임지지 않습니다.
              <br />
              5. 회원이 약관의 규정을 위반함으로써 발생되는 회사의 손해에 대해 약관을 위반한 회원은 손해를 배상해야 하며
              손해로부터 회사를 면책시켜야 합니다.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">제19조 (재판권 및 준거법)</div>
            <p className="p">
              1. 약관은 법률 상충의 원칙 및 회원 소재지와 상관없이 대한민국 법률의 적용을 받습니다.
              <br />
              2. 본 서비스 이용과 관련하여 회사와 회원간 발생한 분쟁에 대해 소송이 제기될 경우 민사소송법 상의 관할
              법원에 제소합니다.
            </p>
          </div>
        </div>
      </div>
      <p className="p">(시행일) 본 약관은 2023년 12월 19일부터 시행됩니다.</p>
    </div>
  );
};
