/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconRelume26 } from "../../icons/IconRelume26";
import "./style.css";

export const Button = ({ buttonStyle, small, darkMode, iconPosition, className, divClassName, text = "Button" }) => {
  return (
    <button className={`button ${iconPosition} ${buttonStyle} small-${small} dark-mode-${darkMode} ${className}`}>
      {iconPosition === "no-icon" && <div className={`text-wrapper ${divClassName}`}>{text}</div>}

      {iconPosition === "trailing" && <div className="div">{text}</div>}

      {["leading", "only", "trailing"].includes(iconPosition) && (
        <IconRelume26
          className="icon-relume"
          color={
            (!darkMode && buttonStyle === "link") ||
            (!darkMode && buttonStyle === "secondary") ||
            (!darkMode && buttonStyle === "tertiary") ||
            (darkMode && buttonStyle === "primary")
              ? "black"
              : "white"
          }
        />
      )}

      {iconPosition === "leading" && <div className="text-wrapper-2">{text}</div>}
    </button>
  );
};

Button.propTypes = {
  style: PropTypes.oneOf(["primary", "link", "secondary", "tertiary"]),
  small: PropTypes.bool,
  darkMode: PropTypes.bool,
  iconPosition: PropTypes.oneOf(["no-icon", "trailing", "only", "leading"]),
  text: PropTypes.string,
};
