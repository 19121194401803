import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Button } from 'antd';
import styled from "styled-components";
import { UploadOutlined } from '@ant-design/icons';

const StyledHeader = styled.div`
  align-items: center;
  background-color: var(--g-white);
  display: flex;
  flex-direction: row;
  gap: 80px;
  position: relative;
  max-width: 1440px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & .title-upload {
    width: 100%;
  }

  & .title-upload {
    max-width: 540px;
    width: 100%;
    display: flex;
  }

  & .both-container {
    display: flex;
    justify-content: center; // Center children horizontally
    align-items: center; // Center children vertically if needed
    width: 100%;
    padding: 112px 64px;

    @media (max-width: 1199px) {
      padding: 0 20px;
      padding: 64px 20px;
  }

  & .title-upload-col {
    display: flex;
    width: 100%;
  }

  & .overlap-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .overlap-wrapper {
    width: 692px;
    height: 640px;
    position: relative;
    display: flex;

    @media (max-width: 767px) {
      height: 309.827px;
      width: 335px;
      justify-content: center;
    }
  }

  & .title-upload {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 640px;
    position: relative;
    width: 100%;
    padding: 0;
  }
  
  & .header-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    position: relative;
    width: 100%;
  }

  & .header-box {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
    justify-content: center;
  }

  & .upload-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
    margin-top: 64px;

    @media (max-width: 768px) {
      margin-top: 48px;
    }
  }

  & .header-title {
    align-self: stretch;
    color: var(--g-title);
    font-family: var(--heading-h1-font-family);
    font-size: var(--heading-h1-font-size);
    font-style: var(--heading-h1-font-style);
    font-weight: var(--heading-h1-font-weight);
    letter-spacing: var(--heading-h1-letter-spacing);
    line-height: var(--heading-h1-line-height);
    margin-top: -1px;
    position: relative;
    justify-content: center;

    @media (max-width: 768px) {
      font-size: var(--heading-h1-mobile-font-family);
      font-family: var(--heading-h1-mobile-font-family);
      font-size: var(--heading-h1-mobile-font-size);
      font-style: var(--heading-h1-mobile-font-style);
      font-weight: var(--heading-h1-mobile-font-weight);
      letter-spacing: var(--heading-h1-mobile-letter-spacing);
      line-height: var(--heading-h1-mobile-line-height);
      text-align: center;

      & .title-part {
        display: block;
      }
    }
  }
      
  & .header-description {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: var(--heading-h5-font-family);
    font-size: var(--heading-h5-font-size);
    font-style: var(--heading-h5-font-style);
    font-weight: var(--heading-h5-font-weight);
    letter-spacing: var(--heading-h5-letter-spacing);
    line-height: var(--heading-h5-line-height);
    position: relative;
    
    @media (max-width: 768px) {
      font-size: var(--heading-h5-mobile-font-family);
      font-family: var(--heading-h5-mobile-font-family);
      font-size: var(--heading-h5-mobile-font-size);
      font-style: var(--heading-h5-mobile-font-style);
      font-weight: var(--heading-h5-mobile-font-weight);
      letter-spacing: var(--heading-h5-mobile-letter-spacing);
      line-height: var(--heading-h5-mobile-line-height);

      text-align: center;

      & .title-description {
        display: block;
      }
    }
  }

  & .upload-box {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-white);
    border: 1px solid;
    border-color: var(--g-line);
    border-radius: 16px;
    box-shadow: var(--large);
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 237px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 60px;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
      padding: 8px 40px;
      height: 180px;
    }
  }

  & .upload-button-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-button);
    border-radius: 68px;
    display: flex;
    gap: 16px;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    position: relative;
    width: 100%;
    max-width: 490px
  }

  & .upload-button {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    position: relative;
    width: 420px;
    height: 80px
    cursor: pointer;
    background-color: var(--g-button);

    @media (max-width: 768px) {
      width: 224px;
      height: 64px
    }
  }

  & .p {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22px;
    position: relative;
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 14px;
      font-family: Roboto-Regular, Helvetica;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  & .upload-icon {
    color: #ffffff;
    font-family: "Font Awesome 6 Pro-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 33.6px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .upload-text {
    color: #ffffff;
    font-family: var(--heading-h5-font-family);
    font-size: var(--heading-h5-font-size);
    font-style: var(--heading-h5-font-style);
    font-weight: var(--heading-h5-font-weight);
    letter-spacing: var(--heading-h5-letter-spacing);
    line-height: var(--heading-h5-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    
    @media (max-width: 768px) {
      font-size: var(--heading-h5-mobile-font-family);
      font-family: var(--heading-h5-mobile-font-family);
      font-size: var(--heading-h5-mobile-font-size);
      font-style: var(--heading-h5-mobile-font-style);
      font-weight: var(--heading-h5-mobile-font-weight);
      letter-spacing: var(--heading-h5-mobile-letter-spacing);
      line-height: var(--heading-h5-mobile-line-height);
    }
  }

  & .frame-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  & .select-image-sentence {
    color: var(--g-subtitle);
    font-family: var(--text-regular-medium-font-family);
    font-size: var(--text-regular-medium-font-size);
    font-style: var(--text-regular-medium-font-style);
    font-weight: var(--text-regular-medium-font-weight);
    letter-spacing: var(--text-regular-medium-letter-spacing);
    line-height: var(--text-regular-medium-line-height);
    position: relative;
    width: max-content;
  }

  & .example-group {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  & .example-image {
    background-color: var(--g-lighter-grey);
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    height: 64px;
    width: 64px;
  }

  & .element {
    height: 64px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 64px;
  }

  & .img {
    height: 62px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 64px;
  }

  & .blurred-ad-mockup {
    height: 422px;
    left: 331px;
    position: absolute;
    top: 119px;
    width: 289px;

    @media (max-width: 776px) {
      left: 161.54px;
      top: 57.52px;
      height: 207.27908px;
      width: 142.29448px;
    }
  }

  & .ad-example-wrapper {
    left: 93px;
    top: 40px;
  }

  & .ad-example-background {
    border-radius: 8px;
    box-shadow: var(--xxlarge);
    height: 560px;
    position: absolute;
    width: 350px;
    left: 93px;
    top: 40px;

    @media (max-width: 776px) {
      left: 45.02px;
      top: 19.36px;
      height: 271.27908px;
      width: 169.29448px;
    }
  }

  & .facebook-ad-mockup {
    height: 560px;
    position: absolute;
    width: 350px;
    left: 93px;
    top: 40px;

    @media (max-width: 776px) {
      left: 45.02px;
      top: 19.36px;
      height: 271.27908px;
      width: 169.29448px;
    }
  }

  & .background-replaced {
    background-color: #000000;
    height: 350px;
    left: 93px;
    top: 157.67px;
    position: absolute;
    width: 350px;
      
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; // This removes any extra space below the image
    }

    @media (max-width: 776px) {
      left: 45.02px;
      top: 74.88px;
      height: 169.29448px;
      width: 169.29448px;
    }
  }

  & .naver-logo-wrapper {
    background-color: #ffffff;
    border-radius: 46.25px;
    box-shadow: 0px 6.17px 6.17px -3.08px #00000008, 0px 15.42px 18.5px -3.08px #00000014;
    height: 74px;
    overflow: hidden;
    position: absolute;
    left: 406px;
    top: 439px;
    width: 74px;

    @media (max-width: 776px) {
      height: 35.824px;
      width: 35.824px;
      left: 196.54px;
      top: 212.52px;
    }
  }

  & .naver-logo {
    height: 64px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 5px;
    width: 64px;

    @media (max-width: 776px) {
      top: 2.47px;
      left: 2.47px;
      height: 30.8833px;
      width: 30.8833px;
    }
  }

  & .facebook-logo-wrapper {
    background-color: #ffffff;
    border-radius: 72.5px;
    box-shadow: 0px 9.67px 9.67px -4.83px #00000008, 0px 24.17px 29px -4.83px #00000014;
    height: 116px;
    left: 72px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    width: 116px;

    @media (max-width: 776px) {
      height: 56.584px;
      width: 56.584px;
      left: 35.02px;
      top: -2.47px;
    }
  }

  & .facebook-logo {
    height: 97px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 9.67px;
    width: 97px;

    @media (max-width: 776px) {
      height: 47.3833px;
      width: 47.3833px;
      top: 4.74px;
      left: 4.74px;
    }
  }

  & .x-logo {
    height: 164px;
    left: 0;
    position: absolute;
    top: 399px;
    width: 164px;

    @media (max-width: 776px) {
      height: 79.8833px;
      width: 79.8833px;
      top: 192.52px;
    }
  }

  & .instagram-logo-wrapper {
    background-color: #ffffff;
    border-radius: 70px;
    box-shadow: 0px 9.33px 9.33px -4.67px #00000008, 0px 23.33px 28px -4.67px #00000014;
    height: 112px;
    overflow: hidden;
    position: absolute;
    left: 583px;
    top: 301px;
    width: 112px;

    @media (max-width: 776px) {
      height: 54.6167px;
      width: 54.6167px;
      left: 283.54px;
      top: 145.52px;
    }
  }

  & .instagram-logo {
    height: 95px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 95px;

    @media (max-width: 776px) {
      height: 46.3833px;
      width: 46.3833px;
      top: 4.74px;
      left: 4.74px;
    }
  }

  & .google-logo-wrapper {
    background-color: #ffffff;
    border-radius: 60px;
    box-shadow: 0px 8px 8px -4px #00000008, 0px 20px 24px -4px #00000014;
    overflow: hidden;
    position: absolute;
    top: 107.17px;
    left: 492.25px;
    height: 96px;
    width: 96px;

    @media (max-width: 776px) {
      height: 46.8833px;
      width: 46.8833px;
      top: 52.52px;
      left: 240.54px;
    }
  }

  & .google-logo {
    height: 84px;
    left: 7.36%;
    object-fit: cover;
    position: absolute;
    top: 6.25%;
    width: 82px;

    @media (max-width: 776px) {
      height: 40.8833px;
      width: 40.8833px;
      top: 3.19px;
      left: 3.59px;
    }
  }

`;

export const Header = ({
  breakpoint,
  className,
  element = "/img/7-1-2-1.png",
  instagramAdMockup = "/img/instagram-ad-mockup-1-1-1.png",
  naverLogo = "/img/in36bktetqoapibgeabo-1-1.png",
  facebookLogo = "/img/facebook-logo-2023-1-1.png",
  frame = "/img/frame-52-1.svg",
  instagramLogo = "/img/instagram-logo-2022-2-1.png",
}) => {
  return (
    <StyledHeader className={`header ${className}`}>
      <div className="both-container">
        <Row gutter={[32, 32]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={11}>
            <Col span={24}>
              <div className="header-box">
                <div className="header-title">
                  <span className="title-part">이커머스 AI </span>
                  <span className="title-part">이미지 생성</span>
                </div>
                <div className="header-description">
                  <span className="title-description">인공지능으로 제품 배경을  </span>
                  <span className="title-description">자유롭게 바꿔보세요.</span>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={24}>
              <div className="upload-container">
                <div className="upload-box">
                  <div className="upload-button-wrapper">
                    <div className="upload-button">
                      <div className="upload-icon">upload</div>
                      <div className="upload-text">이미지 업로드</div>
                    </div>
                  </div>
                  <p className="p">제품 영역을 제외한 배경이 없는 투명한 PNG 파일을 업로드하세요.</p>
                </div>
                <div className="frame-6">
                  <div className="select-image-sentence">
                    <Row>
                      <Col xxs={12} md={24}>
                        <p className="select-image-sentence">이미지가 없으신가요?</p>
                      </Col>
                      <Col xxs={12} md={24}>
                        <p className="select-image-sentence">여기서 하나 선택해 주세요.</p>    
                      </Col>
                    </Row>
                  </div>
                  <div className="example-group">
                    <div className="example-image">
                      <img className="element" alt="Element" src="/img/3-1-2-1.png" />
                    </div>
                    <div className="example-image">
                      <img className="element" alt="Element" src={element} />
                    </div>
                    <div className="example-image">
                      <img className="img" alt="Element" src="/img/1-2-3-1.png" />
                    </div>
                    <div className="example-image">
                      <img className="element" alt="Element" src="/img/6-1-2-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={13}>
            <div className="overlap-container">
              <div className="overlap-wrapper">
                <div className="ad-example-wrapper">
                  <div className="ad-example-background"></div>
                  <img className="blurred-ad-mockup" alt="Instagram ad mockup" src={instagramAdMockup} />
                  <img className="facebook-ad-mockup" alt="Facebook ad mockup" src="/img/facebook-ad-mockup-3-1-1.png" />
                  <div className="background-replaced">
                    <img src="/img/banner-ani.gif" alt="Animation" autoPlay loop muted />
                  </div>
                </div>
                <div className="naver-logo-wrapper">
                  <img className="naver-logo" alt="naver-logo" src={naverLogo} />
                </div>
                <div className="facebook-logo-wrapper">
                  <img className="facebook-logo" alt="Facebook logo" src={facebookLogo} />
                </div>
                <img className="x-logo" alt="Frame" src={frame} />
                <div className="instagram-logo-wrapper">
                  <img className="instagram-logo" alt="Instagram logo" src={instagramLogo} />
                </div>
                <div className="google-logo-wrapper">
                  <img className="google-logo" alt="Google logo" src="/img/google-logo-28fa7991af-seeklogo-1-1.png" />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={0}>
            <div className="upload-container">
              <div className="upload-box">
                <Button className="upload-button" shape="round" type="primary" icon={<UploadOutlined />} size="large">
                  <span className="upload-text">이미지 업로드</span>
                </Button>
                <p className="p">제품 영역을 제외한 배경이 없는 투명한 PNG 파일을 업로드하세요.</p>
              </div>
              <div className="frame-6">
                <Row gutter={[8, 16]}>
                  <Col xxs={24} xs={24} sm={24} md={12}>
                    <div className="select-image-sentence">
                      <Row>
                        <Col xxs={12} md={24}>
                          <p className="select-image-sentence">이미지가 없으신가요?</p>
                        </Col>
                        <Col xxs={12} md={24}>
                          <p className="select-image-sentence">여기서 하나 선택해 주세요.</p>    
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xxs={24} xs={24} sm={24} md={12}>
                    <div className="example-group">
                      <div className="example-image">
                        <img className="element" alt="Element" src="/img/3-1-2-1.png" />
                      </div>
                      <div className="example-image">
                        <img className="element" alt="Element" src={element} />
                      </div>
                      <div className="example-image">
                        <img className="img" alt="Element" src="/img/1-2-3-1.png" />
                      </div>
                      <div className="example-image">
                        <img className="element" alt="Element" src="/img/6-1-2-1.png" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop"]),
  element: PropTypes.string,
  instagramAdMockup: PropTypes.string,
  naverLogo: PropTypes.string,
  facebookLogo: PropTypes.string,
  frame: PropTypes.string,
  instagramLogo: PropTypes.string,
};
